import React, { useReducer, useContext } from "react"
import LoanReducer from "./loanReducer"
import LoanContext from "./loanContext"
import AxiosRequest from "../../services/AxiosRequests"
import AlertContext from "../alert/alertContext"

import {
  SET_LOADING,
  GET_SETTINGS_SUCCESS,
  REFER_SUCCESS,
  ACTION_SUCCESS,
  ACTION_ERROR,
  GET_USER_LOAN,
  GET_USER_LOAN_FAIL,
  GET_USER_LOAN_SUCCESS,
  GET_PROVIDERS_SUCCESS,
  SET_LOAN_SUBMITTING,
  HANDLE_ELIGIBILITY_PREVIOUS_STEP,
  HANDLE_ELIGIBILITY_NEXT_STEP,
  RESET_ELIGIBILITY_FORM_STEP,
  INELIGIBLE_LOAN_APPLICATION,
  ELIGIBLE_LOAN_APPLICATION,
  GET_FAQ_SUCCESS,
  GET_LOAN_HISTORY_SUCCESS,
  GET_OFFERINGS_SUCCESS,
  GET_LOAN_TYPE_SUCCESS,
  GET_TESTIMONIALS,
} from "../types"
import { navigate } from "gatsby"

const LoanState = props => {
  const initialState = {
    providers: [],
    eligibleProviders: [], // like providers, except that they hold list for eligible loan offers AFTER loan is submitted
    faq: [],
    isLoading: false,
    pSettings: [],
    institutionList: [],
    testimonials: [],
    userLoan: null,
    loanHistory: null,
    availableLoanTypes: null,
    currentLoan: null,
    gettingUserLoan: false,
    feedbackStatus: null,
    isSubmitting: false,
    eligibility: {
      activeStep: 0,
      status: false,
      feedBack: null,
    },
  }

  const [state, dispatch] = useReducer(LoanReducer, initialState)
  // const educollectEmail = "info@bursery.com"
  const errMsg = "An error occured, please try again"
  // const authMsg = "Session expired, please log in to continue"

  const { setAlert } = useContext(AlertContext)

  //get All loan providers' settings
  const getProvidersSettings = async () => {
    setLoading()
    try {
      const res = await AxiosRequest("get", "api/ProviderSettings")
      if (res.data.requestSuccessful) {
        dispatch({
          type: GET_SETTINGS_SUCCESS,
          payload: res.data.responseData,
        })
      } else {
        dispatch({
          type: ACTION_ERROR,
        })
      }
    } catch (err) {
      // console.log(err)
      dispatch({
        type: ACTION_ERROR,
      })
    }
  }

  //refer a friend
  const refer = async (payload, resetForm) => {
    setLoading()
    try {
      const values = { ...payload, referralDetails: payload.friends }
      const res = await AxiosRequest("post", "/api/Refer/create", values)
      if (res.data.requestSuccessful) {
        dispatch({
          type: REFER_SUCCESS,
        })
        resetForm({
          payload: {
            message: payload.message,
            userName: "",
            userEmail: "",
            friends: [""],
          },
        })
        if (
          window.location.pathname === "/dashboard/refer-a-friend/" ||
          window.location.pathname === "/dashboard/refer-a-friend"
        ) {
          navigate("/dashboard")
        }
        setAlert(
          "Thank you for the referral, message sent successfully",
          "success"
        )
      } else {
        dispatch({
          type: ACTION_ERROR,
        })
        setAlert(res.data.message, "error")
      }
    } catch (err) {
      // console.log(err)
      dispatch({
        type: ACTION_ERROR,
      })
      setAlert(errMsg, "error")
    }
  }

  //send Feedback from popup form
  const sendFeedback = async payload => {
    const values = { ...payload, comment: payload.message }
    try {
      await AxiosRequest("post", "/api/Feedback/create", values)
    } catch (error) {
      console.log(error)
    }
  }

  //get user's current loan
  const getUserLoan = async payload => {
    dispatch({
      type: GET_USER_LOAN,
    })
    try {
      const res = await AxiosRequest(
        "get",
        `/api/LoanTransaction/loans/users_current_loan_status/${payload}`
      )
      if (res.data.requestSuccessful) {
        console.log("The user's current loan is: ", res.data.responseData)
        dispatch({
          type: GET_USER_LOAN_SUCCESS,
          payload: res.data.responseData,
        })

        // const response = await AxiosRequest(
        //   "get",
        //   `/api/LoanTransaction/${res.data.responseData.id}`,
        // )

        // if (response.data.requestSuccessful) {
        //   dispatch({
        //     type: GET_CURRENT_LOAN_SUCCESS,
        //     payload: response.data.responseData,
        //   })
        // }
      } else {
        dispatch({
          type: GET_USER_LOAN_FAIL,
        })
      }
    } catch (err) {
      dispatch({
        type: ACTION_ERROR,
      })
      // setAlert("Something went wrong, please refresh the page", "error")
    }
  }

  //get user's loan history
  const getLoanHistory = async payload => {
    try {
      const res = await AxiosRequest(
        "get",
        `/api/LoanTransaction/loans/user_loan_status/${payload}`
      )
      if (res.data.requestSuccessful) {
        dispatch({
          type: GET_LOAN_HISTORY_SUCCESS,
          payload: res.data.responseData.items,
        })
      } else {
        dispatch({
          type: ACTION_ERROR,
        })
      }
    } catch (err) {
      dispatch({
        type: ACTION_ERROR,
      })
      // setAlert("Something went wrong, please refresh the page", "error")
    }
  }

  //get loan Providers
  const getProviders = async () => {
    setLoading()
    try {
      const res = await AxiosRequest("get", "/api/Provider")
      if (res.data.requestSuccessful) {
        dispatch({
          type: GET_PROVIDERS_SUCCESS,
          payload: res.data.responseData.items,
        })
      } else {
        dispatch({
          type: ACTION_ERROR,
        })
      }
    } catch (err) {
      //  console.log(err)
      dispatch({
        type: ACTION_ERROR,
      })
    }
  }

  // get eligible loan providers
  const getEligibleProviders = async payload => {
    try {
      const res = await AxiosRequest(
        "get",
        `/api/Provider/provider_eligiblity_offering_by_loan_type_id/${payload}`
      )
      if (res.data.requestSuccessful) {
        dispatch({
          type: GET_OFFERINGS_SUCCESS,
          payload: res.data.responseData,
        })
      } else {
        dispatch({
          type: ACTION_ERROR,
        })
      }
    } catch (err) {
      //  console.log(err)
      dispatch({
        type: ACTION_ERROR,
      })
    }
  }

  // get eligible loan providers
  const getTestimonials = async payload => {
    try {
      const res = await AxiosRequest("get", `/api/Testimonial`)
      if (res.data.requestSuccessful) {
        console.log("resssssssssss", res.data.responseData)
        dispatch({
          type: GET_TESTIMONIALS,
          payload: res.data.responseData,
        })
      } else {
        dispatch({
          type: ACTION_ERROR,
        })
      }
    } catch (err) {
      //  console.log(err)
      dispatch({
        type: ACTION_ERROR,
      })
    }
  }

  const dummyRequestData = {
    pageSize: 10000,
  }

  // get all active institutions
  const getAllInstitutionsAndScholarshipsApiService = async (
    payload = dummyRequestData
  ) => {
    let newPayload = dummyRequestData
    if (payload.searchWord) {
      newPayload = {
        ...newPayload,
        searchWord: payload.searchWord,
      }
    }
    if (payload.pageSize) {
      newPayload = {
        ...newPayload,
        pageSize: payload.pageSize,
      }
    }
    if (payload.pageIndex) {
      newPayload = {
        ...newPayload,
        pageIndex: payload.pageIndex,
      }
    }
    return AxiosRequest(
      "getInstitution",
      `/api/v1/Institution/GetAllActiveInstitutionsWithScholarships`,
      newPayload
    )
    // try {
    //   const res = await AxiosRequest("getInstitution", `/api/v1/Institution/GetAllActiveInstitution`, newPayload);
    //   console.log('hello', res);
    //   if (res.status === 200) {
    //     dispatch({
    //       type: GET_ALL_ACTIVE_INSTITUTIONS_SUCCESS,
    //       payload: res.data.payload.items,
    //     })
    //   } else {
    //     dispatch({
    //       type: ACTION_ERROR,
    //     })
    //   }
    // } catch (err) {
    //   console.log(err)
    //   dispatch({
    //     type: ACTION_ERROR,
    //   })
    // }
  }

  const submitPartner = async (payload, resetForm) => {
    setSubmitting()
    try {
      const res = await AxiosRequest("post", `/api/Partner/create`, payload)
      if (res.data.requestSuccessful) {
        dispatch({
          type: ACTION_SUCCESS,
        })
        setAlert("Message submitted successfully", "success")
        resetForm()
      } else {
        setAlert(res.data.message, "error")
        dispatch({
          type: ACTION_ERROR,
        })
      }
    } catch (err) {
      actionError(err)
    }
  }

  //submit contact
  const submitContact = async (payload, resetForm) => {
    setSubmitting()
    try {
      const res = await AxiosRequest("post", "/api/Contact", payload)
      if (res.data.requestSuccessful) {
        dispatch({
          type: ACTION_SUCCESS,
        })
        resetForm()
        setAlert("Thank you, message sent successfully", "success")
      } else {
        setAlert(res.data.message, "error")
        dispatch({
          type: ACTION_ERROR,
        })
      }
    } catch (err) {
      actionError(err)
    }
  }

  //check eligibility
  const checkEligibility = async (payload, resetForm) => {
    setSubmitting()
    try {
      const res = await AxiosRequest(
        "post",
        "/api/Eligibility/checkeligibility",
        payload
      )
      if (res.data.requestSuccessful) {
        dispatch({
          type: ELIGIBLE_LOAN_APPLICATION,
          payload: res.data.responseData,
        })
      } else {
        dispatch({
          type: INELIGIBLE_LOAN_APPLICATION,
          payload: res.data.message,
        })
      }
      handleEligibilityNextStep()
      resetForm()
    } catch (err) {
      actionError(err)
    }
  }

  // accept loan offer
  const acceptLoan = async loanRef => {
    try {
      const res = await AxiosRequest(
        "post",
        `api/LoanTransaction/accept_offer/${loanRef}`
      )
      if (res.data.requestSuccessful) {
        const loanAccepted = res.data.responseData

        return loanAccepted ? true : false
      }
    } catch (error) {
      throw error
    }
  }

  // Decline an approved loan offer
  const declineLoan = async loanRef => {
    try {
      const res = await AxiosRequest(
        "post",
        `api/LoanTransaction/decline_offer/${loanRef}`
      )
      if (res.data.requestSuccessful) {
        const loanDeclined = res.data.responseData

        return loanDeclined ? true : false
      }
    } catch (error) {
      throw error
    }
  }

  //Renew an existing loan - POF
  const renewLoan = async payload => {
    try {
      const res = await AxiosRequest(
        "post",
        `api/LoanTransaction/renew_loan_request`,
        payload
      )

      if (res.data?.requestSuccessful) {
        setAlert("Loan Renewal request submitted successfully", "success")
        return res.data.responseData
      } else {
        setAlert(res.data.message || "Loan Renewal error. Try again", "error")
        return false
      }
    } catch (error) {
      if (error.response.data.message) {
        setAlert(error.response.data.message, "error")
      } else actionError(error)
    }
  }

  const getPartners = async () => {
    try {
      const res = await AxiosRequest("get", "api/Organization")
      if (res.data.requestSuccessful) {
        const partners = res.data.responseData.items
        const activePartners = partners.filter(
          partner => partner.status === true
        )
        return activePartners
      }
    } catch (error) {
      throw error
    }
  }

  const getFaqs = async () => {
    setLoading()
    try {
      const res = await AxiosRequest("get", "/api/FAQ?IsApproved=true")
      if (res.data.requestSuccessful) {
        dispatch({
          type: GET_FAQ_SUCCESS,
          payload: res.data.responseData.items,
        })
      } else {
        dispatch({
          type: ACTION_ERROR,
        })
      }
    } catch (err) {
      //  console.log(err)
      dispatch({
        type: ACTION_ERROR,
      })
    }
  }

  const getLoanTypes = async () => {
    setLoading()
    try {
      const res = await AxiosRequest("get", "/api/AvailableLoanType")
      if (res.data.requestSuccessful) {
        dispatch({
          type: GET_LOAN_TYPE_SUCCESS,
          payload: res.data.responseData.items,
        })
      } else {
        dispatch({
          type: ACTION_ERROR,
        })
      }
    } catch (err) {
      //  console.log(err)
      dispatch({
        type: ACTION_ERROR,
      })
    }
  }

  //handle next steps for the eligibility form
  const handleEligibilityNextStep = () =>
    dispatch({
      type: HANDLE_ELIGIBILITY_NEXT_STEP,
    })

  //hanle previous steps for the eligibility form
  const handleEligibilityPreviousStep = () =>
    dispatch({
      type: HANDLE_ELIGIBILITY_PREVIOUS_STEP,
    })

  const resetEligibilityFormStep = () =>
    dispatch({
      type: RESET_ELIGIBILITY_FORM_STEP,
    })

  //Check for errors
  //this will be handled in the axios configuration
  // const checkError = err => {
  //   if (err.response && err.response.status === 401) {
  //     logOut()
  //   } else {
  //     return setAlert(errMsg, "error")
  //   }
  // }

  const actionError = err => {
    // console.log(err)
    setAlert(errMsg, "error")
    dispatch({ type: ACTION_ERROR })
  }

  const setSubmitting = () => dispatch({ type: SET_LOAN_SUBMITTING })

  //set Loading
  const setLoading = () => dispatch({ type: SET_LOADING })

  return (
    <LoanContext.Provider
      value={{
        ...state,
        getProvidersSettings,
        getFaqs,
        getProviders,
        refer,
        sendFeedback,
        getUserLoan,
        submitPartner,
        submitContact,
        getPartners,
        checkEligibility,
        handleEligibilityPreviousStep,
        handleEligibilityNextStep,
        resetEligibilityFormStep,
        getLoanHistory,
        getEligibleProviders,
        acceptLoan,
        declineLoan,
        getLoanTypes,
        getTestimonials,
        getAllInstitutionsApiService: getAllInstitutionsAndScholarshipsApiService,
        renewLoan,
      }}
    >
      <>{props.children}</>
    </LoanContext.Provider>
  )
}

export default LoanState
