import React, { useReducer } from 'react';
import AlertContext from './alertContext';
import alertReducer from './alertReducer';

import { SET_ALERT, REMOVE_ALERT } from '../types';

const AlertState = props => {

    const initialState = {
        alert: null,
        open: false
    }

    const [state, dispatch] = useReducer(alertReducer, initialState)

    const setAlert = (msg, type) => {
        dispatch({
            type: SET_ALERT,
            payload: { msg, type }
        });
    }

    const removeAlert = () => {
        dispatch({
            type: REMOVE_ALERT,
        });
    }
    
    const { alert, open } = state;
    return <AlertContext.Provider
        value = {{
            alert,
            open,
            setAlert,
            removeAlert
        }}
    >

        {props.children}
    </AlertContext.Provider>
}



export default AlertState