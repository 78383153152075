// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-application-js": () => import("./../../../src/pages/application.js" /* webpackChunkName: "component---src-pages-application-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dashboard-index-js": () => import("./../../../src/pages/dashboard/index.js" /* webpackChunkName: "component---src-pages-dashboard-index-js" */),
  "component---src-pages-dashboard-profile-js": () => import("./../../../src/pages/dashboard/profile.js" /* webpackChunkName: "component---src-pages-dashboard-profile-js" */),
  "component---src-pages-dashboard-refer-a-friend-js": () => import("./../../../src/pages/dashboard/refer-a-friend.js" /* webpackChunkName: "component---src-pages-dashboard-refer-a-friend-js" */),
  "component---src-pages-eligibility-check-js": () => import("./../../../src/pages/eligibility-check.js" /* webpackChunkName: "component---src-pages-eligibility-check-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-old-dashboard-js": () => import("./../../../src/pages/oldDashboard.js" /* webpackChunkName: "component---src-pages-old-dashboard-js" */),
  "component---src-pages-olduser-js": () => import("./../../../src/pages/olduser.js" /* webpackChunkName: "component---src-pages-olduser-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-partner-js": () => import("./../../../src/pages/partner.js" /* webpackChunkName: "component---src-pages-partner-js" */),
  "component---src-pages-partner-with-us-js": () => import("./../../../src/pages/partnerWithUs.js" /* webpackChunkName: "component---src-pages-partner-with-us-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-refer-js": () => import("./../../../src/pages/refer.js" /* webpackChunkName: "component---src-pages-refer-js" */),
  "component---src-pages-scholarships-js": () => import("./../../../src/pages/scholarships.js" /* webpackChunkName: "component---src-pages-scholarships-js" */),
  "component---src-pages-user-activate-js": () => import("./../../../src/pages/user/activate.js" /* webpackChunkName: "component---src-pages-user-activate-js" */),
  "component---src-pages-user-email-js": () => import("./../../../src/pages/user/email.js" /* webpackChunkName: "component---src-pages-user-email-js" */),
  "component---src-pages-user-forgot-password-js": () => import("./../../../src/pages/user/forgot-password.js" /* webpackChunkName: "component---src-pages-user-forgot-password-js" */),
  "component---src-pages-user-login-js": () => import("./../../../src/pages/user/login.js" /* webpackChunkName: "component---src-pages-user-login-js" */),
  "component---src-pages-user-register-js": () => import("./../../../src/pages/user/register.js" /* webpackChunkName: "component---src-pages-user-register-js" */),
  "component---src-pages-user-reset-password-js": () => import("./../../../src/pages/user/reset-password.js" /* webpackChunkName: "component---src-pages-user-reset-password-js" */),
  "component---src-pages-user-verify-js": () => import("./../../../src/pages/user/verify.js" /* webpackChunkName: "component---src-pages-user-verify-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

