// import api from './Api'
// import axios from 'axios'

// const AxiosRequest = (requestType, appendUrl, payLoad) => {

//     const baseUrl ='kk'; //remove every instance

//     const axiosGet = () => {
//         return new Promise ((resolve, reject) => {
//             api.get(appendUrl).then(res => {
//                 resolve(res);
//             })
//             .catch(error => {
//                 reject(error)
//             })
//         })
//     }

//     const axiosPost = () => {
//         return new Promise ((resolve, reject) => {
//             api.post(
//                 appendUrl, payLoad
//                 ,
//                 {
//                     headers: {
//                         'Content-Type': 'application/json'
//                     }
//                 }
//             )
//             .then(res => {
//             resolve(res);
//             })
//             .catch(error => {
//                 checkError(error)
//                 console.log(error)
//                 reject(error)
//             })
//         })
//     }

//     const axiosDelete = () => {
//         return new Promise ((resolve, reject) => {
//             api.delete(`${baseUrl}${appendUrl}`).then(res => {
//                 resolve(res);
//             })
//             .catch(error => {
//                 reject(error)
//             })
//         })
//     }

//     const axiosPut = () => {
//         return new Promise ((resolve, reject) => {
//             api.put(
//                 appendUrl, payLoad
//                 ,
//                 {
//                     headers: {
//                         'Content-Type': 'application/json'
//                     }
//                 }
//             )
//             .then(res => {
//             resolve(res);
//             })
//             .catch(error => {
//                 checkError(error)
//                 console.log(error)
//                 reject(error)
//             })
//         })
//     }

//     const checkError = err => {
//         if (err.response.status === 401) {
//             console.log("it is 401")
//             localStorage.clear()
//             api.defaults.headers.Authorization = ""
//             delete api.defaults.headers.Authorization
//             window.location = "/user/login"
//         }
//     }

//     switch (requestType) {
//         case 'post':
//             return axiosPost();

//         case 'get':
//             return axiosGet();

//         case 'delete':
//             return axiosDelete();

//         case 'put':
//             return axiosPut();

//         default:
//             break;
//     }
// }

// export default AxiosRequest

import axios from "axios"
import Cookies from "js-cookie"

const baseURL = process.env.GATSBY_API_URL
const baseAltURL = process.env.MARKETPLACE_API_URL

let headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
}

export const axiosInstance = axios.create({
  baseURL: baseURL,
  headers,
})

export const axiosInstanceAlt = axios.create({
  baseURL: baseAltURL,
  headers,
})

const AxiosRequest = (requestType, url, data) => {
  // axiosInstance.interceptors.response.use((response) => response, (error) => {
  //     if (typeof error.response === "undefined") {
  //         console.log("caught cors error")
  //     }
  //     return Promise.reject(error)
  // })

  // if (Date.parse(new Date()) > Date.parse(localStorage.valid) || !localStorage.valid && Cookies.get("token")) {
  //   //token expired
  //   // We wait for an action here just in case timer pause
  //   if (typeof window !== "undefined") {
  //     //  localStorage.clear()
  //     //  Cookies.remove("token")
  //      delete axiosInstance.defaults.headers.Authorization
  //     // return logOut()
  //   }
  // }

  if (Cookies.get("token")) {
    if (Date.parse(new Date()) > Date.parse(localStorage.valid)) {
      if (typeof window !== "undefined") {
        localStorage.clear()
        Cookies.remove("token")
        delete axiosInstance.defaults.headers.Authorization
        // return logOut()
      }
    }
  }

  switch (requestType) {
    case "post":
      return axiosInstance.post(url, data)

    case "get":
      if (data) {
        return axiosInstance.get(url, { params: data })
      } else {
        return axiosInstance.get(url)
      }

    case "getInstitution":
      if (data) {
        return axiosInstanceAlt.get(url, { params: data })
      } else {
        return axiosInstanceAlt.get(url)
      }

    case "delete":
      return axiosInstance.delete(url, data)

    case "put":
      return axiosInstance.put(url, data)

    default:
      break
  }
}

export default AxiosRequest
