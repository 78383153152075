import React, { createContext, useReducer, useState } from "react"
import { VerifyDataReducer } from "./reducers/VerifyDataReducer"

export const VerifyDataContext = createContext()
export const ActiveStepContext = createContext()
export const InstitutionContext = createContext()
export const signInContext = createContext()

const CredPalContextProvider = ({ children }) => {
  const initialVerifyData = null

  const [verifyData, dispatchVerifyData] = useReducer(
    VerifyDataReducer,
    initialVerifyData
  )
  const [activeStep, setActiveStep] = useState(verifyData ? 1 : 0)
  const [otherInstitution, setOtherInstitution] = useState(false)
  const [signIn, setSignIn] = useState(0)

  // const [activeStep, setActiveStep] = useState(1)

  return (
    <InstitutionContext.Provider
    value={[otherInstitution, setOtherInstitution]}
  >
    <VerifyDataContext.Provider value={{ verifyData, dispatchVerifyData }}>
      <ActiveStepContext.Provider value={[activeStep, setActiveStep]}>
        <signInContext.Provider value={[signIn, setSignIn]}>
          {children}
          </signInContext.Provider>
      </ActiveStepContext.Provider>
    </VerifyDataContext.Provider>
    </InstitutionContext.Provider>
  )
}

export default CredPalContextProvider
