export const VerifyDataReducer = (state, action) => {
    switch (action.type) {
        case 'UPDATE_VERIFY_DATA':
            return {
                ...state,
                loanType: action.verifyData.loanType,
                verifiedData: action.verifyData.res,
                amount: action.verifyData.amount,
                regNo: action.verifyData.regNo,
                organizationCode: action.verifyData.organizationCode,
                organizationName: action.verifyData.organizationName,
                email: action.verifyData.email
            }    
        default:
            return state;
    }
}