import React from "react"
import CredPalContextProvider from "./CredPalContext"
import AuthState from "./auth/AuthState"
import AlertState from "./alert/AlertState"
import LoanState from "./loan/LoanState"

const ContextWrapper = ({ children }) => {
  return (
    <CredPalContextProvider>
      <AlertState>
        <AuthState>
          <LoanState>{children}</LoanState>
        </AuthState>
      </AlertState>
    </CredPalContextProvider>
  )
}

export default ContextWrapper
