import { SET_ALERT, REMOVE_ALERT } from '../types';

const alertReducer = (state, action) => {
    switch (action.type){
        case SET_ALERT:
            return {
                ...state,
                alert: action.payload,
                open: true
            }

        case REMOVE_ALERT:
            return {
                ...state,
                alert: null,
                open: false
            }
        default:
            return state;
    }
}

export default alertReducer